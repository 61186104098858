@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

body {
  margin: 0;
  font-family: "Manrope";
}

code {
  font-family: "Manrope";
}

#root {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.serviceCardStyle {
  border-radius: 32px;
  border: 1px solid rgba(255, 255, 255, 0.22);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(6px);
}

.animated-text {
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #000, #4488ff, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 2000ms linear;
  -webkit-background-clip: text;
  opacity: 0;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
    opacity: 1;
  }
  100% {
    background-position: 500%;
    opacity: 0;
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

::-webkit-scrollbar {
  background-color: #4488ff44;
  width: 5px;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #4488ff;
  border-radius: 5px;
}

@keyframes hoverAnimation {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}

.marquee {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  box-sizing: border-box;
}

.abs-center {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px; /* Need a specific value to work */
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
