.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gradient-border {
  border-radius: 24px;
  background-origin: border-box;
  border: 1.2px solid #eaecf0;
  transition: border-color 0.3s ease-in-out;
}

.gradient-border:hover {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(45deg, #f5f5f5, #9a88fb, #f5f5f5, #1570ef) 1;
  border-radius: 24px; /* Apply radius to gradient border */
  background-origin: border-box;
}

.gradient-text {
  background: linear-gradient(90deg, #5899f3 0%, #9a88fb 55.17%, #fff 94.47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-text_colorheading {
  background: linear-gradient(
    80deg,
    #5899f3 57.4%,
    #9a88fb 86.46%,
    #fff 101.16%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Outfit:wght@100..900&display=swap");

.gradient-border-network-card {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(45deg, #f5f5f5, #9a88fb, #f5f5f5, #1570ef) 1;
  border-radius: 24px; /* Apply radius to gradient border */
  background-origin: border-box;
}

.gradient-text-faq {
  background: linear-gradient(187deg, #f9fafb 47.04%, #959595 96.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* .gradient-border-network-card:hover {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(45deg, #f5f5f5, #9a88fb, #f5f5f5, #1570ef) 1;
  border-radius: 24px; /* Apply radius to gradient border */
/* background-origin: border-box;
} */
